import "./style.scss";

const ThreeDotLoading = ({ color = "white" }) => {
  return (
    <div className="spinner">
      <div className="bounce1" style={{ backgroundColor: color }}></div>
      <div className="bounce2" style={{ backgroundColor: color }}></div>
      <div className="bounce3" style={{ backgroundColor: color }}></div>
    </div>
  );
};

export default ThreeDotLoading;

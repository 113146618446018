import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./css/main.scss";
import { Spin } from "antd";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoginPage from "./components/login/LoginPage";
import "bootstrap/dist/css/bootstrap.min.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
const App = lazy(() => import("./App"));

root.render(
  <RouterProvider
    router={createBrowserRouter([
      {
        path: "/",
        element: (
          <Suspense
            fallback={
              <Spin style={{ minHeight: "100vh" }} size="large">
                <div className="appLoader" />
              </Spin>
            }
          >
            <LoginPage />
          </Suspense>
        ),
      },
      {
        path: "*",
        element: (
          <Suspense
            fallback={
              <Spin style={{ minHeight: "100vh" }} size="large">
                <div className="appLoader" />
              </Spin>
            }
          >
            <App />
          </Suspense>
        ),
      },
    ])}
  ></RouterProvider>
);

import React, { useState } from "react";
import "./loginPage.scss";
import { Form } from "react-bootstrap";
import LoginIcon from "../../images/login-icon.svg";
import ThreeDotLoading from "../../common-component/threedot-loading/ThreeDotLoading";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const [formValues, setFormValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (name, value) => {
    setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const onSubmitForm = (e) => {
    e.preventDefault();

    if (validateForm()) {
      setIsLoading(true);

      const inter = setInterval(() => {
        navigate("/home");
        clearInterval(inter);
      }, 2000);
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formValues.name?.trim()) newErrors.name = "Please Enter Name";
    if (!formValues.password?.trim())
      newErrors.password = "Please Enter Password";
    // if (!formValues.noOfOpening?.trim())
    //   newErrors.noOfOpening = "Please Enter No Of Opening";
    // if (formValues.name !== "admin" && formValues.password !== "admin") {
    //   newErrors.name = "Incorrect Name";
    //   newErrors.password = "Incorrect Password";
    // }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <div className="app-container login-background">
      <div className="login-form">
        <div>
          <div className="title">Welcome Back</div>
          <form onSubmit={onSubmitForm} className="custom-form">
            <Form.Group className="mb-3">
              <Form.Label className="label-form">Name</Form.Label>
              <Form.Control
                className={`input-form ${errors.name ? "error" : ""}`}
                type="text"
                placeholder="name"
                value={formValues.name}
                onChange={(e) => handleChange("name", e.target.value)}
              />
              <div className="input-error">{errors.name}</div>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="label-form">Password</Form.Label>
              <Form.Control
                className={`input-form ${errors.password ? "error" : ""}`}
                type="password"
                placeholder="**********"
                value={formValues.password}
                onChange={(e) => handleChange("password", e.target.value)}
              />
              <div className="input-error">{errors.password}</div>
            </Form.Group>

            <div className="text-center mb-3">
              <button
                type="submit"
                className="btn btn-primary agora-primary-btn login-btn"
              >
                {isLoading ? (
                  <ThreeDotLoading />
                ) : (
                  <>
                    <span className="me-2">Log in</span>
                    <img src={LoginIcon} />
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
